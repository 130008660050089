<template>
  <button type="button" v-bind:class="[btnType, btnSize]"><slot></slot></button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    size: String,
    type: String,
  },
  data() {
    return {
      // disabled = this.props.disabled
    };
  },
  computed: {
    btnSize() {
      var btnClass;
      switch (this.size) {
        case "sm":
          btnClass = "btn-sm";
          break;
        case "lg":
          btnClass = "btn-lg";
          break;
        case "xl":
          btnClass = "btn-xl";
          break;
        default:
          btnClass = "btn-md";
      }
      return btnClass;
    },
    btnType() {
      var btnClass;
      switch (this.type) {
        case "plain":
          btnClass = this.disabled ? "btn-plain-disabled" : "btn-plain";
          break;
        case "secondary":
          btnClass = this.disabled ? "btn-secondary-disabled" : "btn-secondary";
          break;
        default:
          btnClass = this.disabled ? "btn-primary-disabled" : "btn-primary";
      }
      return btnClass;
    },
  },
};
</script>

<style lang="postcss" scoped>
button {
  @apply inline-flex items-center font-medium rounded-md shadow-sm;
}
.btn-primary {
  @apply text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none;
}
.btn-primary-disabled {
  @apply text-white bg-indigo-400  cursor-not-allowed;
}
.btn-secondary {
  @apply text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none;
}
.btn-secondary-disabled {
  @apply text-indigo-500 bg-indigo-50   cursor-not-allowed;
}
.btn-plain {
  @apply border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none;
}
.btn-plain-disabled {
  @apply border border-gray-200 text-gray-500 bg-white cursor-not-allowed;
}

/* Breakpoint max-width: 639px; */
.btn-sm {
  @apply px-2 py-1 text-sm;
}
.btn-md {
  @apply px-4 py-2 text-base;
}
.btn-lg {
  @apply px-6 py-3 text-lg;
}
.btn-xl {
  /* Dekstop */
  @apply px-4 py-2 text-lg;
}

/* Breakpoint min-width: 640px; */
@media screen(sm) {
  .btn-sm {
    @apply px-2 py-1 text-sm;
  }
  .btn-md {
    @apply px-4 py-2 text-base;
  }
  .btn-lg {
    @apply px-6 py-3 text-lg;
  }
  .btn-xl {
    /* Dekstop */
    @apply px-8 py-4 text-xl;
  }
}

/* Breakpoint max-width: 1024px; */
@media screen(lg) {
  .btn-sm {
    @apply px-2 py-1 text-sm;
  }
  .btn-md {
    @apply px-4 py-2 text-base;
  }
  .btn-lg {
    @apply px-6 py-3 text-lg;
  }
  .btn-xl {
    /* Dekstop */
    @apply px-8 py-4 text-xl;
  }
}
/* Breakpoint max-width: 1536px; */
@media screen(2xl) {
  .btn-sm {
    @apply px-2 py-1 text-sm;
  }
  .btn-md {
    @apply px-4 py-2 text-base;
  }
  .btn-lg {
    @apply px-6 py-3 text-lg;
  }
  .btn-xl {
    /* Dekstop */
    @apply px-8 py-4 text-xl;
  }
}
</style>

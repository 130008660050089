import { createRouter, createWebHistory } from "vue-router";

import componentsUi from "./componentsUi";
import invoice from "./invoice";
import auth from "./auth";
import sales from "./sales";
import settings from "./settings";
import NotFound from "@/views/404.vue";
import Dashboard from "@/views/Dashboard.vue";

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  ...sales,
  ...invoice,
  ...auth,
  ...settings,
  ...componentsUi,
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

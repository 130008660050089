<template>
  <div>
    <div class="ipt-top-text">
      <label :class="[labelClass]">{{ label ? $t(label) : "" }} <span v-if="required" class="ipt-required">*</span> </label>

      <div v-if="tooltip" class="ipt-tooltip">
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
        </svg>
        <span class="ipt-tooltip-text"> {{ $t(tooltip) }}</span>
      </div>
    </div>
    <!-- prettier-ignore -->
    <div class="ipt-wrapper-def" :class="[wrapperClass]">
      <span v-if="addon" :class="[addonClass]">
        {{addon}}
      </span>
      <input
        :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
        class="input-def"
        :class="[inputClass,  addon ? 'rounded-r-md' : 'rounded-md' ]"
        :id="id"
        :type="type"
        :placeholder="[placeholder ? $t(placeholder) : '']"
        :required="required ? true : false"
        :autocomplete="autocomplete ? 'on' : 'off'"
        :disabled="disabled ? true : false"
        :readonly="readonly ? true : false"
      />
      <div v-if="error" class="ipt-error-icon">
        <svg class="h-6 w-6 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <!-- <p v-if="error" class="ipt-error-text" :id="id + '-error'">{{ $t(errorText) }}</p> -->
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    tooltip: String,
    disabled: Boolean,
    required: Boolean,
    autocomplete: Boolean,
    readonly: Boolean,
    id: String,
    type: String,
    placeholder: String,
    addon: String,
    modelValue: {
      type: String,
      default: "",
    },
    error: Boolean,
    errorText: String,
  },
  computed: {
    labelClass() {
      if (this.disabled === true) {
        return "ipt-label-disabled";
      } else if (this.error === true) {
        return "ipt-label-error";
      } else {
        return "ipt-label";
      }
    },

    inputClass() {
      if (this.disabled === true) {
        return "input-primary-disabled";
      } else if (this.error === true) {
        return "input-primary-error";
      } else if (this.readonly === true) {
        return "input-primary-readonly";
      } else {
        return "input-primary";
      }
    },
    addonClass() {
      if (this.disabled === true) {
        return "ipt-addon-disabled";
      } else if (this.error === true) {
        return "ipt-addon-error";
      } else {
        return "ipt-addon";
      }
    },
    wrapperClass() {
      if (this.disabled === true) {
        return "ipt-wrapper-disabled";
      } else if (this.error === true) {
        return "ipt-wrapper-error";
      } else if (this.readonly === true) {
        return "ipt-wrapper-readonly";
      } else {
        return "ipt-wrapper";
      }
    },
  },
  methods: {},
};
</script>

<style lang="postcss" scoped>
/* Top Text Default */
.ipt-top-text {
  @apply mb-1 text-sm flex justify-between;
}
.ipt-required {
  @apply text-red-400 ml-1;
}
.ipt-label {
  @apply text-gray-700;
}
.ipt-label-disabled {
  @apply text-gray-400;
}
.ipt-label-error {
  @apply text-red-500;
}

.ipt-tooltip {
  @apply relative inline-block;
}

.ipt-tooltip .ipt-tooltip-text {
  @apply hidden w-64 bg-gray-700 bg-opacity-70 border shadow-sm rounded-md text-white px-2 py-3 absolute z-50;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -246px;
}
.ipt-tooltip:hover .ipt-tooltip-text {
  @apply block;
}

.ipt-wrapper-def {
  @apply mt-1 flex relative shadow-sm rounded-md focus:outline-none focus-within:outline-none outline-none transition duration-150;
}
.ipt-wrapper {
  @apply bg-white border border-gray-300 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500;
}
.ipt-wrapper-disabled {
  @apply bg-white border border-gray-200 focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500;
}
.ipt-wrapper-error {
  @apply bg-white border border-red-300 focus-within:ring-1 focus-within:ring-red-500 focus-within:border-red-500;
}
.ipt-wrapper-readonly {
  @apply bg-gray-100 border border-gray-200;
}

.ipt-addon {
  @apply inline-flex rounded-l-md items-center px-3 border-r border-gray-300 bg-gray-50 text-gray-500;
}
.ipt-addon-disabled {
  @apply inline-flex rounded-l-md items-center px-3 border-r border-gray-200 bg-gray-50 text-gray-300;
}
.ipt-addon-error {
  @apply inline-flex rounded-l-md items-center px-3 border-r border-red-300 bg-gray-50 text-red-500;
}

.input-def {
  @apply px-3 py-2  w-full focus:outline-none outline-none;
}
.input-primary {
  @apply text-gray-500 focus:text-gray-700 placeholder-gray-300 focus:placeholder-gray-200 border-gray-300;
}
.input-primary-disabled {
  @apply border-gray-200 placeholder-gray-300 text-gray-300 cursor-not-allowed;
}
.input-primary-error {
  @apply pr-10 border-red-300 text-red-500 placeholder-red-300 focus:placeholder-red-200;
}
.input-primary-readonly {
  @apply border-gray-300 bg-gray-50 placeholder-gray-300 text-gray-600 cursor-pointer;
}

.ipt-error-icon {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}
</style>

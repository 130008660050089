<template>
  <layout>
    <template v-slot:title>Form inputs & controls </template>
    <template v-slot:content>
      <div class="mx-auto space-y-4">
        <div>
          <h2 class="">Input primary</h2>
          <div class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto">
            <div class="space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <w-input class="w-64" v-model="iptPri.values.default" v-bind="iptPri.default" />
              <w-input class="w-64" v-model="iptPri.values.disabled" v-bind="iptPri.disabled" />
              <w-input class="w-64" v-model="iptPri.values.error" v-bind="iptPri.error" />
            </div>
            <div class="mt-4 space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ iptPri.values.default }}</pre>
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ this.iptPri.values.disabled }}</pre>
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ this.iptPri.values.error }}</pre>
            </div>
          </div>
        </div>
        <div>
          <h2 class="">Input with addon</h2>
          <div class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto">
            <div class="space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <w-input class="w-64" v-model="iptAddon.values.default" v-bind="iptAddon.default" />
              <w-input class="w-64" v-model="iptAddon.values.disabled" v-bind="iptAddon.disabled" />
              <w-input class="w-64" v-model="iptAddon.values.error" v-bind="iptAddon.error" />
            </div>
            <div class="mt-4 space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ iptAddon.values.default }}</pre>
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ this.iptAddon.values.disabled }}</pre>
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ this.iptAddon.values.error }}</pre>
            </div>
          </div>
        </div>
        <div>
          <h2 class="">Input readonly</h2>
          <div class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto">
            <div class="space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <w-input class="w-64" v-model="iptReadonly.values.default" v-bind="iptReadonly.default" />
            </div>
            <div class="mt-4 space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ iptReadonly.values.default }}</pre>
            </div>
          </div>
        </div>
        <div>
          <h2 class="">Toggles</h2>
          <div class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto">
            <div class="space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <w-toggle class="" v-model="toggles.values.default" v-bind="toggles.default" />
            </div>
            <div class="mt-4 space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ toggles.values.default }}</pre>
            </div>
          </div>
        </div>
        <div>
          <h2 class="">Checkboxes</h2>
          <div class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto">
            <div class="space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <w-checkbox v-model="checkboxes.values.first" v-bind="checkboxes.first" />
              <w-checkbox v-model="checkboxes.values.second" v-bind="checkboxes.second" />
              <w-checkbox v-model="checkboxes.values.firstDisabled" v-bind="checkboxes.firstDisabled" />
              <w-checkbox v-model="checkboxes.values.secondDisabled" v-bind="checkboxes.secondDisabled" />
            </div>
            <div class="mt-4 space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:justify-around">
              <pre class="w-64 bg-gray-800 rounded-md text-white p-2 text-sm">{{ checkboxes.values }}</pre>
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from "@/views/components-ui/Layout.vue";

export default {
  components: {
    layout,
  },
  methods: {},
  data() {
    return {
      iptPri: {
        values: {
          default: "test",
          disabled: "disabled",
          error: "",
        },
        default: {
          disabled: false,
          required: false,
          autocomplate: false,
          error: false,
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
          tooltip: "Her indsætter du mail",
        },
        disabled: {
          disabled: true,
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
        },
        error: {
          required: true,
          error: true,
          autocomplate: false,
          errorText: "i18n-trans asd ads aojmiijo asd jjioasd  asd asd asd",
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
        },
      },
      iptReadonly: {
        values: {
          default: "jakob@webits.dk",
        },
        default: {
          disabled: false,
          readonly: true,
          autocomplate: false,
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
          tooltip: "Her indsætter du mail",
        },
      },
      iptAddon: {
        values: {
          default: "test",
          disabled: "disabled",
          error: "",
        },
        default: {
          disabled: false,
          required: true,
          autocomplate: false,
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
          tooltip: "Her indsætter du mail",
          addon: "Test",
        },
        disabled: {
          disabled: true,
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
          addon: "https://",
        },
        error: {
          required: true,
          error: true,
          autocomplate: false,
          errorText: "i18n-trans asd ads aojmiijo asd jjioasd  asd asd asd",
          id: "email",
          type: "email",
          placeholder: "elon@tesla.com",
          label: "Email",
          addon: "https://",
        },
      },
      toggles: {
        values: {
          default: false,
        },
        default: {},
      },
      checkboxes: {
        values: {
          first: true,
          second: false,
          firstDisabled: true,
          secondDisabled: false,
        },
        first: {
          id: "comments",
          title: "Enabled",
          disabled: false,
          description: "Get notified when someones posts a comment on a posting.",
        },
        second: {
          id: "comments",
          title: "Enabled",
          disabled: false,
          description: "Get notified when someones posts a comment on a posting.",
        },
        firstDisabled: {
          id: "comments",
          title: "Disabled",
          disabled: true,
          description: "Get notified when someones posts a comment on a posting.",
        },
        secondDisabled: {
          id: "comments",
          title: "Disabled",
          disabled: true,
          description: "Get notified when someones posts a comment on a posting.",
        },
      },
    };
  },
};
</script>

<style></style>

const routes = [
  {
    path: "/sales/dashboard",
    name: "sales-dashboard",
    component: () => import("@/views/sales/Dashboard.vue"),
  },
  {
    path: "/sales/invoices",
    name: "sales-invoices",
    component: () => import("@/views/sales/invoice/Invoices.vue"),
  },
  {
    path: "/sales/offers",
    name: "sales-offers",
    component: () => import("@/views/sales/offer/Offers.vue"),
  },
  {
    path: "/sales/subscriptions",
    name: "sales-subscriptions",
    component: () => import("@/views/sales/subscription/Subscriptions.vue"),
  },
  {
    path: "/sales/products",
    name: "sales-products",
    component: () => import("@/views/sales/product/Products.vue"),
  },
  {
    path: "/sales",
    name: "sales-to-dashboard",
    redirect: { name: "sales-dashboard" },
  },
];
export default routes;

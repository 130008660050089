<template>
  <div class="checkbox-wrapper">
    <div class="ipt-checkbox-wrapper">
      <input :disabled="disabled ? true : false" :id="id" :name="id" :checked="modelValue ? true : false" @click="$emit('update:modelValue', !modelValue)" type="checkbox" class="ipt-checkbox" />
    </div>
    <div class="checkbox-text-wrapper">
      <label :id="id + '-label'" :for="id" class="checkbox-label">{{ $t(title) }}</label>
      <p :id="id + '-description'" :for="id" class="checkbox-text">{{ $t(description) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: String,
    disabled: Boolean,
  },
};
</script>
<style lang="postcss" scoped>
.checkbox-wrapper {
  @apply relative flex items-start;
}
.ipt-checkbox {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;
}
.ipt-checkbox-wrapper {
  @apply flex items-center h-5;
}
.checkbox-text-wrapper {
  @apply ml-3 text-sm;
}

.checkbox-label {
  @apply font-medium text-gray-700;
}
.checkbox-text {
  @apply text-gray-500;
}
</style>

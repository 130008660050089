import houseSvg from "@/assets/svg/house.svg";
import scaleSvg from "@/assets/svg/scale.svg";
export const MainMenu = {
  namespaced: true,
  // initial state
  state: {
    mainMenu: [
      {
        name: "main-menu.dashboard",
        routeName: "dashboard",
        svg: houseSvg,
      },
      {
        name: "main-menu.sale.subname",
        svg: scaleSvg,
        isOpen: false,
        submenu: [
          {
            name: "main-menu.sale.dashboard",
            routeName: "sales-dashboard",
            badge: {
              type: "info",
              content: "2",
            },
          },
          {
            name: "main-menu.sale.invoices",
            routeName: "sales-invoices",
          },
          {
            name: "main-menu.sale.offers",
            routeName: "sales-offers",
          },
          {
            name: "main-menu.sale.subscriptions",
            routeName: "sales-subscriptions",
          },
          {
            name: "main-menu.sale.products",
            routeName: "sales-products",
          },
        ],
      },
      {
        name: "main-menu.accounting.subname",
        isOpen: false,
        submenu: [
          {
            name: "main-menu.accounting.dashboard",
          },
          {
            name: "main-menu.accounting.expenses",
          },
          {
            name: "main-menu.accounting.bank",
          },
          {
            name: "main-menu.accounting.cash-draft",
          },
          {
            name: "main-menu.accounting.transactions",
          },
        ],
      },
    ],
    profileMenu: {
      isOpen: false,
      menuItemGroup: [
        {
          name: "my-profile",
          group: [
            {
              name: "profile-menu.my-profile",
            },
          ],
        },
        {
          name: "settings",
          group: [
            {
              name: "profile-menu.user-settings",
              routeName: "settings-user-to-default",
            },
            {
              name: "profile-menu.company-settings",
            },
          ],
        },
        {
          name: "support",
          group: [
            {
              name: "profile-menu.support",
              outgoingLink: "https://support.webits.dk",
              outgoingLinkIsBlank: true,
            },
          ],
        },
      ],
    },
  },

  // getters
  getters: {
    getMain: (state) => state.mainMenu,
    getProfile: (state) => state.profileMenu,
  },

  // actions
  actions: {
    toggleSubmenu(context, menuItemName) {
      context.commit("toggleSubmenu", menuItemName);
    },
    toggleProfile(context) {
      context.commit("toggleProfile");
    },
  },

  // mutations
  mutations: {
    toggleSubmenu(state, payload) {
      var index = state.mainMenu.findIndex((x) => x.name == payload);
      state.mainMenu[index].isOpen = !state.mainMenu[index].isOpen;
    },
    toggleProfile(state) {
      state.profileMenu.isOpen = !state.profileMenu.isOpen;
    },
  },
};

const routes = [
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "/forgot",
    name: "auth-forgot",
    component: () => import("@/views/auth/Forgot/Default.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/auth/Forgot/Options.vue"),
      },
      {
        path: "password",
        name: "auth-forgot-password",
        component: () => import("@/views/auth/Forgot/Password.vue"),
      },
      {
        path: "to-factor-auth",
        name: "auth-forgot-to-factor-auth",
        component: () => import("@/views/auth/Forgot/ToFactorAuth.vue"),
      },
      {
        path: "email",
        name: "auth-forgot-email",
        component: () => import("@/views/auth/Forgot/Email.vue"),
      },
    ],
  },
];
export default routes;

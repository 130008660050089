import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";
import VueClickAway from "vue3-click-away";
import i18n from "@/locales/i18n";
//Tailwindcss import
import "@/assets/css/tailwind.css";

import "@/assets/css/transitions.css";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueClickAway);
app.use(i18n);

// Create Sentry instance

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
// Sentry.init({
//   app,
//   dsn: "https://3e7ab8758b40423eb498cafae1273e28@o470231.ingest.sentry.io/5960751",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["flowbits-dev.webits.dev", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

/**
 * Global import of Components
 */
// Default input component
import Input from "@/components/form-inputs/Input.vue";
app.component("w-input", Input);
// Toggle component
import Toggle from "@/components/form-inputs/Toggle.vue";
app.component("w-toggle", Toggle);
// Checkbox component
import Checkbox from "@/components/form-inputs/Checkbox.vue";
app.component("w-checkbox", Checkbox);
// Button component
import Button from "@/components/buttons/Button.vue";
app.component("w-button", Button);

app.mount("#app");
const axiosSetup = axios.create({
  baseURL: process.env.API_URL,
  timeout: 3000,
  headers: { "Cache-Control": "no-cache" },
});

app.config.globalProperties.$axios = axiosSetup;

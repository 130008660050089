const routes = [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/Index.vue"),
  },
  {
    path: "/settings/user",
    name: "settings-user-to-default",
    redirect: { name: "settings-user-security-gdpr" },
  },
  {
    path: "/settings/user/profile",
    name: "settings-user-general-profile",
    component: () => import("@/views/settings/user/general/Profile.vue"),
  },
  {
    path: "/settings/user/appearence",
    name: "settings-user-general-appearence",
    component: () => import("@/views/settings/user/general/Appearence.vue"),
  },
  {
    path: "/settings/user/notifications",
    name: "settings-user-general-notifications",
    component: () => import("@/views/settings/user/general/Notifications.vue"),
  },
  {
    path: "/settings/user/language",
    name: "settings-user-general-language",
    component: () => import("@/views/settings/user/general/Language.vue"),
  },
  {
    path: "/settings/user/integrations",
    name: "settings-user-general-integrations",
    component: () => import("@/views/settings/user/general/Integrations.vue"),
  },
  {
    path: "/settings/user/gdpr",
    name: "settings-user-security-gdpr",
    component: () => import("@/views/settings/user/security/Gdpr.vue"),
  },
  {
    path: "/settings/user/security",
    name: "settings-user-security",
    component: () => import("@/views/settings/user/security/Security.vue"),
  },
];
export default routes;

<template>
  <button role="switch" :value="modelValue" @click="$emit('update:modelValue', !modelValue)" class="btn-switch" :class="[modelValue ? 'btn-switch-on' : 'btn-switch-off']">
    <span class="btn-switch-inner" :class="[modelValue ? 'btn-switch-inner-on' : 'btn-switch-inner-off']"></span>
  </button>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="postcss" scoped>
.btn-switch {
  @apply relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.btn-switch-on {
  @apply bg-indigo-600;
}
.btn-switch-off {
  @apply bg-gray-200;
}
.btn-switch-inner {
  @apply pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200;
}
.btn-switch-inner-on {
  @apply translate-x-5;
}
.btn-switch-inner-off {
  @apply translate-x-0;
}
</style>

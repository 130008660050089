const dev = { template: "<router-view></router-view>" };
// const prod = { template: "<div>prod</div>" };
import Index from "@/views/components-ui/Index.vue";
import Buttons from "@/views/components-ui/Buttons.vue";
import Inputs from "@/views/components-ui/Inputs.vue";
const routesDev = [
  {
    path: "/ui",
    component: dev,
    children: [
      {
        path: "all",
        component: Index,
      },
      {
        path: "buttons",
        component: Buttons,
      },
      {
        path: "inputs",
        component: Inputs,
      },
    ],
  },
];
const routesProd = [];

const compUi = process.env.NODE_ENV === "production" ? routesProd : routesDev;

export default compUi;

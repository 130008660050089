<template>
  <router-view> </router-view>
</template>

<script>
export default {
  beforeMount() {
    this.$i18n.locale = this.$store.getters["appMetaData/getCurrentLang"] || "en";
  },
};
</script>
<style></style>

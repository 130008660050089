<template>
  <layout>
    <template v-slot:title> {{ $t("dashboard.title") }} </template>
    <template v-slot:content>
      <div class="grid grid-cols-2 mb-3">
        <div class="col-span-1">
          <h3 class="text-gray-600 text-lg">{{ $t("dashboard.result-in-dkk") }}</h3>
        </div>
        <div class="col-span-1">
          <h3 class="text-right text-gray-600 text-lg">{{ $t("dashboard.accounting-period", { date: "20-21" }) }}</h3>
        </div>
      </div>
      <dl class="grid grid-rows-3 grid-cols-4 grid-flow-col bg-white shadow">
        <div class="row-span-1 border-r-2 border-b-2">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-base font-normal text-gray-900">Indtægter</dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                71,897
                <span class="ml-2 text-sm font-medium text-gray-500"> from 70,946 </span>
              </div>

              <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <!-- Heroicon name: solid/arrow-sm-up -->
                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only"> Increased by </span>
                12%
              </div>
            </dd>
          </div>
        </div>
        <div class="row-span-1">
          <div class="row-span-1 border-r-2 border-b-2">
            <div class="px-4 py-5 sm:p-6">
              <dt class="text-base font-normal text-gray-900">Udgifter</dt>
              <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                  71,897
                  <span class="ml-2 text-sm font-medium text-gray-500"> from 70,946 </span>
                </div>

                <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                  <!-- Heroicon name: solid/arrow-sm-up -->
                  <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only"> Increased by </span>
                  12%
                </div>
              </dd>
            </div>
          </div>
        </div>
        <div class="row-span-1 border-r-2">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-base font-normal text-gray-900">Resultat</dt>
            <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                71,897
                <span class="ml-2 text-sm font-medium text-gray-500"> from 70,946 </span>
              </div>

              <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <!-- Heroicon name: solid/arrow-sm-up -->
                <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only"> Increased by </span>
                12%
              </div>
            </dd>
          </div>
        </div>
        <div class="row-span-3 col-span-3">
          <img src="https://pbs.twimg.com/media/BwcWvZCCMAAHXHK.jpg" class="max-h-80" alt="" />
        </div>
      </dl>
    </template>
  </layout>
</template>

<script>
import layout from "@/layouts/Default.vue";
export default {
  components: {
    layout,
  },
};
</script>

<style></style>

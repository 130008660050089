<template>
  <layout>
    <template v-slot:title>Buttons </template>
    <template v-slot:content>
      <div class="mx-auto space-y-4">
        <div>
          <h2 class="">
            Button primary
            <w-button class="ml-2" size="sm" @click="toggleDisabledPri"> toggle Disabled</w-button>
          </h2>
          <div v-if="!btnPri.code" class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:items-end sm:justify-around">
            <w-button :disabled="btnPri.btnSm.disabled" :size="btnPri.btnSm.size"> Button sm </w-button>
            <w-button v-bind="btnPri.btnMd"> Button (md) </w-button>
            <w-button :disabled="btnPri.btnLg.disabled" :size="btnPri.btnLg.size"> Button lg </w-button>
            <w-button :disabled="btnPri.btnXl.disabled" :size="btnPri.btnXl.size"> Button xl </w-button>
          </div>
        </div>
        <div>
          <h2 class="">
            Button Secondary
            <w-button class="ml-2" size="sm" type="secondary" @click="toggleDisabledSec"> toggle Disabled</w-button>
          </h2>
          <div v-if="!btnSec.code" class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:items-end sm:justify-around">
            <w-button :disabled="btnSec.btnSm.disabled" :size="btnSec.btnSm.size" :type="btnSec.btnSm.type"> Button sm </w-button>
            <w-button v-bind="btnSec.btnMd"> Button (md) </w-button>
            <w-button :disabled="btnSec.btnLg.disabled" :size="btnSec.btnLg.size" :type="btnSec.btnLg.type"> Button lg </w-button>
            <w-button :disabled="btnSec.btnXl.disabled" :size="btnSec.btnXl.size" :type="btnSec.btnXl.type"> Button xl </w-button>
          </div>
        </div>
        <div>
          <h2 class="">
            Button Plain
            <w-button class="ml-2" size="sm" type="plain" @click="toggleDisabledPlain"> toggle Disabled</w-button>
          </h2>
          <div v-if="!btnPlain.code" class="bg-white mt-2 px-4 py-6 border rounded-md mx-auto space-y-4 flex flex-col items-center justify-start sm:space-y-0 sm:flex-row sm:items-end sm:justify-around">
            <w-button :disabled="btnPlain.btnSm.disabled" :size="btnPlain.btnSm.size" :type="btnPlain.btnSm.type"> Button sm </w-button>
            <w-button v-bind="btnPlain.btnMd"> Button (md) </w-button>
            <w-button :disabled="btnPlain.btnLg.disabled" :size="btnPlain.btnLg.size" :type="btnPlain.btnLg.type"> Button lg </w-button>
            <w-button :disabled="btnPlain.btnXl.disabled" :size="btnPlain.btnXl.size" :type="btnPlain.btnXl.type"> Button xl </w-button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import layout from "@/views/components-ui/Layout.vue";

export default {
  components: {
    layout,
  },
  methods: {
    clicked() {
      alert("Button clicked");
    },
    toggleDisabledPri() {
      this.btnPri.btnSm.disabled = !this.btnPri.btnSm.disabled;
      this.btnPri.btnMd.disabled = !this.btnPri.btnMd.disabled;
      this.btnPri.btnLg.disabled = !this.btnPri.btnLg.disabled;
      this.btnPri.btnXl.disabled = !this.btnPri.btnXl.disabled;
    },
    toggleDisabledSec() {
      this.btnSec.btnSm.disabled = !this.btnSec.btnSm.disabled;
      this.btnSec.btnMd.disabled = !this.btnSec.btnMd.disabled;
      this.btnSec.btnLg.disabled = !this.btnSec.btnLg.disabled;
      this.btnSec.btnXl.disabled = !this.btnSec.btnXl.disabled;
    },
    toggleDisabledPlain() {
      this.btnPlain.btnSm.disabled = !this.btnPlain.btnSm.disabled;
      this.btnPlain.btnMd.disabled = !this.btnPlain.btnMd.disabled;
      this.btnPlain.btnLg.disabled = !this.btnPlain.btnLg.disabled;
      this.btnPlain.btnXl.disabled = !this.btnPlain.btnXl.disabled;
    },
  },
  data() {
    return {
      btnPri: {
        code: false,
        btnSm: {
          disabled: false,
          size: "sm",
        },
        btnMd: {
          disabled: false,
        },
        btnLg: {
          disabled: false,
          size: "lg",
        },
        btnXl: {
          disabled: false,
          size: "xl",
        },
      },
      btnSec: {
        code: false,
        btnSm: {
          disabled: false,
          size: "sm",
          type: "secondary",
        },
        btnMd: {
          disabled: false,
          size: "md",
          type: "secondary",
        },
        btnLg: {
          disabled: false,
          size: "lg",
          type: "secondary",
        },
        btnXl: {
          disabled: false,
          size: "xl",
          type: "secondary",
        },
      },
      btnPlain: {
        code: false,
        btnSm: {
          disabled: false,
          size: "sm",
          type: "plain",
        },
        btnMd: {
          disabled: false,
          size: "md",
          type: "plain",
        },
        btnLg: {
          disabled: false,
          size: "lg",
          type: "plain",
        },
        btnXl: {
          disabled: false,
          size: "xl",
          type: "plain",
        },
      },
    };
  },
};
</script>

<style></style>

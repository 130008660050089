export const appMetaData = {
  namespaced: true,
  // initial state
  state: {
    app_url: "localhost",
    theme: localStorage.theme,
    app_lang: localStorage.app_lang,
  },

  // getters
  getters: {
    getCurrentLang: (state) => {
      return state.app_lang;
    },
  },

  // actions
  actions: {
    changeLang(context, payload) {
      context.commit("onLangChange", payload);
    },
  },

  // mutations
  mutations: {
    initialiseAppInfo(state, payload) {
      localStorage.setItem("theme", payload);
    },
    uninitialiseAppInfo() {
      localStorage.removeItem("theme");
    },
    onLangChange(state, payload) {
      payload.i18n.locale = payload.lang;
      localStorage.setItem("app_lang", payload.lang);
    },
  },
};

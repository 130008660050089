<template>
  <div>
    <div class="relative bg-white border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary">
      <label v-if="attrs.label" :for="attrs.id" class="absolute bg-white -top-2 left-2 -mt-px inline-block px-1 text-sm font-medium text-gray-900"> {{ attrs.label }}</label>
      <div class="relative rounded-md">
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
          <!-- Heroicon name: solid/mail -->
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
        </div>
        <input :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" v-bind="attrs" class="outline-none w-full pl-7 sm:text-sm rounded-md" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    classes: {
      type: Object,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      attrs: {
        id: this.$props.id,
        type: this.$props.type,
        name: this.$props.name,
        label: this.$props.label,
        placeholder: this.$props.placeholder,
      },
      classesLabel: this.$props.classes.label,
      classesInput: this.$props.classes.input,
    };
  },
};
</script>

<!--
  Usage:
    <template>
      <h1>Forgoot password</h1>
      <input-with-icon v-model="formdata.email" v-bind="form.email.attrs" :classes="form.email.classes"></input-with-icon>
    </template>

    <script>
    import inputWithIcon from "@/components/InputGroups/inputWithIcon.vue";

    export default {
      components: {
        inputWithIcon,
      },
      data() {
        return {
          formdata: {
            email: "",
          },
          form: {
            email: {
              attrs: {
                id: "email",
                type: "email",
                name: "email",
                placeholder: "email@email.dk",
              },
              classes: {
                label: [],
                input: [],
              },
            },
          },
        };
      },
    };
    </script>


  Src: https://learnvue.co/2021/01/everything-you-need-to-know-about-vue-v-model/

-->

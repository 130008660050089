import { createI18n } from "vue-i18n";

// IMPORT OF english trans files
import en from "./en/en.json";
import enSettings from "./en/settings.json";
import enComponentsUi from "./en/componentsUi.json";
import enSalesDashboard from "./en/sales/dashboard.json";
import enSalesInvoices from "./en/sales/invoices.json";
import enSalesOffers from "./en/sales/offers.json";
import enSalesProducts from "./en/sales/products.json";
import enSalesSubscriptions from "./en/sales/subscriptions.json";

// IMPORT OF danish trans files
import da from "./da/da.json";
import daSettings from "./da/settings.json";

// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
const messages = {
  en: {
    "i18n-trans": "i18n-trans",
    ...en,
    ui: {
      ...enComponentsUi,
    },
    ...enSettings,
    sales: {
      dashboard: {
        ...enSalesDashboard,
      },
      invoices: {
        ...enSalesInvoices,
      },
      offers: {
        ...enSalesOffers,
      },
      products: {
        ...enSalesProducts,
      },
      subscriptions: {
        ...enSalesSubscriptions,
      },
    },
  },
  da: {
    "i18n-trans": "i18n-trans",
    ...da,
    ...daSettings,
  },
};

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: "da", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;
